// import AsyncStorage from '@react-native-async-storage/async-storage';
import createDataContext from './createDataContext';
import senseiApi from '../api/sensei';
// import { navigate } from '../navigationRef';

const sensorReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'get_devices':
            var tempDevices = action.payload.devices;
            if (tempDevices.length > state.devices.length) {
                // console.log("Sorting by lastupdate.");
                tempDevices.sort((a: any, b: any) => (new Date(a.lastupdate) < new Date(b.lastupdate)) ? 1 : -1);
                tempDevices.forEach((device: any, index: number) => {
                    tempDevices[index].weight = index;
                    let mostRecent = new Date(tempDevices[index].lastupdate);
                    mostRecent.setSeconds(mostRecent.getSeconds() + 300);
                    let now = new Date();
                    if (mostRecent > now) {
                        tempDevices[index].online = true;
                    } else {
                        tempDevices[index].online = false;
                    }
                });
            } else {
                // console.log("Sorting by weight.");
                tempDevices.forEach((device: any, index: number) => {
                    state.devices.forEach((device2: any, index2: number) => {
                        if (device.did === device2.did) {
                            device.weight = device2.weight;
                        }
                    });
                    let mostRecent = new Date(tempDevices[index].lastupdate);
                    mostRecent.setSeconds(mostRecent.getSeconds() + 300);
                    let now = new Date();
                    if (mostRecent > now) {
                        tempDevices[index].online = true;
                    } else {
                        tempDevices[index].online = false;
                    }
                });
                tempDevices.sort((a: any, b: any) => (a.weight > b.weight) ? 1 : -1);
            }


            return {...state, devices: tempDevices};
        default:
            return state;
    }
};

const fetchDevices = (dispatch: any) => async () => {
    const response = await senseiApi.get('/get-devices-mysupplier');
    dispatch({ type: 'get_devices', payload: response.data });
    // console.log("Found devices:");
    // console.log(response.data);
};

// const createTrack = (dispatch: any) => async (name: any, locations: any) => {
//     await senseiApi.post('/tracks', { name, locations });
//     console.log(name, locations.length);
// };

export const { Provider, Context } = createDataContext(
    sensorReducer,
    { fetchDevices },
    {devices: []}
);