import React, { Fragment, useState, useContext } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Context as SettingsContext } from '../context/SettingsContext';

function Settings(props: any) {
    const { t } = useTranslation();
    const changeLanguage = (code: any) => {
      i18n.changeLanguage(code);
    };
    const [languageOption, setLanguageOption] = useState(i18n.language);
    const {state, displayOffline, setTempScale } = useContext(SettingsContext);
    // const [tempScale, setTempScale] = useState(props.tempScale);
  
    function languageChange(event: any) {
      changeLanguage(event.target.value);
      setLanguageOption(event.target.value);
    }
    function tempChange(event: any) {
      setTempScale(event.target.value);
      // props.setTempScale(event.target.value);
    }
  
    return (
      <Fragment>
        <div id="settings">
          <div className="settings-options">
          <h3>{t("Language")}:</h3>
          <select value={languageOption} onChange={languageChange}>
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="de">Deutsche</option>
          </select>
          <h3>{t("temp-scale")}:</h3>
          <select value={state.tempScale} onChange={tempChange}>
            <option value="F">°F</option>
            <option value="C">°C</option>
          </select>
          <h3>{t("hide-offline")}:</h3>
          <input type="checkbox" checked={state.hideOffline} onChange={()=> {
            displayOffline(!state.hideOffline);
          }}/>
          </div>
        </div>
      </Fragment>
    )
  }

  export default Settings;