import React, { useEffect, useState, useContext } from 'react';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
//import { ipcRenderer } from 'electron';
import Backend from 'i18next-xhr-backend';
import './App.css';
// @ts-ignore
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";
import Settings from "./screens/Settings"
import Dashboard from "./screens/Dashboard"
import DeviceFull from "./screens/DeviceFull"
import { Context as SensorContext } from './context/SensorContext';
// import SelectDevice from './components/SelectDevice';
// import { version } from '../package.json';

const logoImage = require('./logo-2.png');

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: 'https://sensei.pierasystems.com/locales/{{lng}}/{{ns}}.json',
        },
    });

function Main(props: any) {
    const { t } = useTranslation();
    // const history = useHistory();

    const {state, fetchDevices} = useContext(SensorContext);
    // const devRef = useRef(useContext(SensorContext));
    // const [minuteAvgs, setMinuteAvgs] = useState<any>([]);
    // const [minuteAvgsCloud, setMinuteAvgsCloud] = useState([]);
    // const [minuteAvgsLocal, setMinuteAvgsLocal] = useState([]);
    // const [events, setEvents] = useState([]);
    // const [devices, setDevices] = useState<any>([]);
    // const [init, setInit] = useState(false);
    // const [deviceConnected, setDeviceConnected] = useState(false);
    // const [deviceName, setDeviceName] = useState("");
    // const [messageBar, setMessageBar] = useState("...");
    const [deviceOption, setDeviceOption] = useState(-1);
    const [tempScale, setTempScale] = useState('F');
    // const [online, setOnline] = useState(false);
    // const [appPath, setAppPath] = useState("");

    useEffect(() => {
        async function tick() {
            await fetchDevices();
            // console.log(sensorContext.state.devices);
            
            // if (sensorContext.state.devices.length > 0){
            //     console.log(sensorContext.state.devices[0].did);
            //     setDeviceOption(sensorContext.state.devices[0].did);
            // }
                
        }
        tick();
        const timer = setInterval(() => {
            tick();
        }, 30 * 1000);
        return (() => {
            clearInterval(timer);
        });
    }, []);

    useEffect(()=> {
        // console.log(state.devices);
        if (deviceOption === -1 && state.devices.length > 0) {
            setDeviceOption(state.devices[0].did)
        }
    }, [state.devices]);

    // useEffect(() => {
    //     console.log("state update")
    //     if (state) {
    //         var tempDevices = state.devices;
    //         if (devices.length < state.devices.length) {
    //             console.log("Sorting by lastupdate.");
    //             tempDevices.sort((a: any, b: any) => (new Date(a.lastupdate) < new Date(b.lastupdate)) ? 1 : -1);
    //             tempDevices.forEach((device: any, index: number) => {
    //                 tempDevices[index].weight = index;
    //             });
    //         } else {
    //             console.log("Sorting by weight.");

    //             tempDevices.forEach((device: any, index: number) => {
    //                 devices.forEach((device2: any, index2: number) => {
    //                     if (device.did == device2.did) {
    //                         device.weight = device2.weight;
    //                     }
    //                 }
    //                 );
    //             });
    //             tempDevices.sort((a: any, b: any) => (a.weight > b.weight) ? 1 : -1);
    //         }

    //         setDevices(tempDevices);
    //     }
    // }, [state]);


    // function deviceChange(event: any) {
    //     console.log("Device changed: " + event.target.value);
    //     history.push("/device/" + event.target.value);
    //     setDeviceOption(event.target.value);
    // }

    return (
        <Router>
        {/* <Router basename={'/dev'}> */}
            <main>
                <nav>
                    <div id="logo">
                        <img src={logoImage.default} alt="Piera Systems Logo" />
                    </div>
                    <ul>
                        <li><NavLink exact activeClassName="active" to="/">{t('Dashboard')}</NavLink></li>
                        <li><NavLink activeClassName="active" to={"/device/" + deviceOption}>{t('Details')}</NavLink></li>
                        <li><NavLink activeClassName="active" to="/settings">{t('Settings')}</NavLink></li>
                    </ul>

                </nav>
                <div id="route-wrapper">
                    <Switch>
                        {/*<Route path="/" exact component={Dashboard} chartData={this.state.minuteAvgs} />*/}
                        <Route path="/" exact render={(props: any) => <Dashboard {...props} deviceOption={deviceOption} tempScale={tempScale} ></Dashboard>} />
                        <Route path="/device/:did" render={(props: any) => <DeviceFull {...props} deviceOption={deviceOption} tempScale={tempScale} setDevice={setDeviceOption}></DeviceFull>} />
                        {/* <Route path="/analysis" render={(props: any) => <Analysis {...props} deviceOption={deviceOption} key={deviceOption} ></Analysis>} /> */}
                        <Route path="/settings" render={(props: any) => <Settings {...props} tempScale={tempScale} setTempScale={setTempScale}></Settings>} />
                    </Switch>
                </div>
                {/* <div id="change-device">
          <div className={(online) ? "online" : "offline"}></div>
          <select name="devices" onChange={deviceChange} value={deviceOption}>
            {state.devices.map((device: any) =>
              <option key={device.did} value={device.did}>{device.name}</option>
            )}
          </select>
        </div> */}
            </main>

        </Router>
    );
}

// function Dashboard(props: any) {
//   const [tempChartData, setChartData] = useState([]);
//   const [tempEventData, setEventData] = useState([]);
//   const [tempDevice, setTempDevice] = useState(0);
//   const [latestID, setLatestID] = useState(-1);
//   const { t } = useTranslation();

//   useEffect(() => {
//     //setTempDevice(props.deviceOption);
//     // if (props.chartData.length > 0) {
//     //   setLatestID(findLatest(props.chartData, tempDevice));
//     //   setChartData(props.chartData);
//     // }

//     //setEventData(props.events);

//   }, [props.device, tempDevice]);//, tempEventData, tempDevice, latestID]);

//   return (
//     <Fragment>
//       <div id="dashboard">
//         {props.devices.map((device: any) =>
//           <div key={device.did} className="device">
//             <Device deviceOption={device.did} lastUpdate={device.lastupdate} deviceName={(device.name) ? device.name : device.iotid} chartData={[device.pm100aqi, device.pm25aqi]} tempScale={props.tempScale} events={device.events}></Device>
//           </div>
//         )}
//       </div>
//     </Fragment>
//   )
// }

// function Analysis(props: any) {
//   const [tempChartData, setChartData] = useState([]);
//   const [tempDevice, setTempDevice] = useState(0);

//   useEffect(() => {
//     setChartData(props.chartData);
//   }, [props.chartData]);
//   useEffect(() => {
//     setTempDevice(props.deviceOption);
//   }, [props.device]);
//   return (
//     <Fragment>
//       <AnalysisChart chartData={tempChartData} deviceOption={tempDevice} type={"PM2.5"}></AnalysisChart>
//     </Fragment>
//   )
// }

// function Settings(props: any) {
//   const { t } = useTranslation();
//   const changeLanguage = (code: any) => {
//     i18n.changeLanguage(code);
//   };
//   const [languageOption, setLanguageOption] = useState(i18n.language);
//   const [tempScale, setTempScale] = useState(props.tempScale);

//   function languageChange(event: any) {
//     changeLanguage(event.target.value);
//     setLanguageOption(event.target.value);
//   }
//   function tempChange(event: any) {
//     setTempScale(event.target.value);
//     props.setTempScale(event.target.value);
//   }

//   return (
//     <Fragment>
//       <div id="settings">
//         <h3>{t("Language")}:</h3>
//         <select value={languageOption} onChange={languageChange}>
//           <option value="en">English</option>
//           <option value="fr">Français</option>
//           <option value="es">Español</option>
//           <option value="de">Deutsche</option>
//         </select>
//         <h3>{t("temp-scale")}:</h3>
//         <select value={tempScale} onChange={tempChange}>
//           <option value="F">°F</option>
//           <option value="C">°C</option>
//         </select>
//       </div>
//     </Fragment>
//   )
// }

export default Main;