import React, { useEffect, useState } from 'react'
//import Chart from "chart.js";
// import ChartBuilder from '../chart-builder';
import { Doughnut } from 'react-chartjs-2';
// import { pcToPm, pm25Aqi, pm100Aqi, vocAqi, findLatest } from '../calculations';
import { useTranslation } from "react-i18next";
import { getAqiColor, getAqiLevel } from '../calculations';


// let charts: any[] = [];

export default function AqiGauge(props: any) {
  // const [chartRef] = useState(React.createRef());
  // const [chartIndex, setChartIndex] = useState(0);
  // const [pmValue, setPmValue] = useState(0.0);
  // const [aqiValue, setAqiValue] = useState(0);
  const { t } = useTranslation();
  //const [aqiCategory, setAqiCategory] = useState("");
  // let chart: any = null;
  // const [chart, setChart] = useState({});

  const [data, updateData] = useState({
    labels: [
      "AQI"
    ],
    datasets: [{
      data: [5, 100],
      backgroundColor: [
        "rgba(0,228,0,1.0)",
        "rgba(127,127,127,0.4)"
      ],
      hoverBackgroundColor: [
        "rgba(0,228,0,1.0)",
        "rgba(127,127,127,0.4)"
      ],
      borderWidth: [
        0, 0
      ]
    }]
  });

  const options = {
    cutout: "75%",
    circumference: 288,
    rotation: 216,
    animation: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      },
    },
    responsive: true,
  };

  // useEffect(() => {
  //     console.log("Creating AQI gauge");
  //     // @ts-ignore
  //     const myChartRef = chartRef.current.getContext("2d");
  //     charts.push(ChartBuilder.buildGauge(myChartRef));
  //     setChartIndex(charts.length - 1);
  //     charts[charts.length - 1].addAQIGaugeData(props.aqi, props.type);
  //     return () => {
  //         charts[chartIndex] = null;
  //     }
  // }, []);

  useEffect(() => {
    // @ts-ignore
    // let i = findLatest(props.chartData, props.device);
    // if (!init) {
    //     // @ts-ignore
    //     const myChartRef = chartRef.current.getContext("2d");
    //     chart.push(ChartBuilder.buildGauge(myChartRef));
    //     setChartIndex(chart.length - 1);
    // }
    //console.log(props.chartData[i]);
    // if (charts[chartIndex]) {
    // @ts-ignore
    // charts[chartIndex].addAQIGaugeData(props.aqi, props.type);

    let gaugeAQI = props.aqi;
    let aqiLevel = getAqiLevel(props.aqi);
    if (gaugeAQI < 10) {
      gaugeAQI = 10;
    }
    updateData(data => {
      return {
        ...data,
        datasets: [{
          data: [gaugeAQI / 5, (500 - gaugeAQI) / 5],
          backgroundColor: [
            getAqiColor(aqiLevel),
            "rgba(127,127,127,0.4)"
          ],
          hoverBackgroundColor: [
            getAqiColor(aqiLevel),
            "rgba(127,127,127,0.4)"
          ],
          borderWidth: [
            0, 0
          ]
        }]
      }
    });
    // console.log(charts);
    // }
    // if (props.chartData) { 
    // chart.addGaugeData(props.chartData, props.type, props.device)
    // let pmValues = pcToPm(props.chartData);
    // if (props.type === "PM1.0") {
    // let aqi = pm25Aqi(pmValues.pm10);
    // setAqiValue(props.chartData);
    //setAqiCategory(aqi.category);
    // setPmValue(parseFloat(pmValues.pm10.toFixed(2)));
    // }
    // if (props.type === "PM2.5") {
    // let aqi = pm25Aqi(pmValues.pm25);
    // setAqiValue(props.chartData);
    //setAqiCategory(aqi.category);
    // setPmValue(parseFloat(pmValues.pm25.toFixed(2)));
    // }
    // if (props.type === "PM10") {
    // let aqi = pm100Aqi(pmValues.pm100);
    // setAqiValue(props.chartData);
    //setAqiCategory(aqi.category);
    // setPmValue(parseFloat(pmValues.pm100.toFixed(2)));
    // }
    // if (props.type === "VOC") {
    // let aqi = vocAqi(props.chartData.voc);
    // setAqiValue(props.chartData);
    //setAqiCategory(aqi.category);
    //setPmValue(-1);
    // }

    // }
  }, [props.aqi, props.pmvalue]);

  return (
    <div className="aqi-gauge">
      <div className="gauge-top">
        <h3>{props.type}</h3>
        {(props.pmvalue > 0) ? <div className="aqi-gauge-pm-value">{props.pmvalue.toFixed(3) || "..."} <small className="unit">(ug/m<sup>3</sup>)</small></div> : null}
      </div>
      <div className="gauge-container">
        <div className="gauge-aqi-wrapper">
          <span className="aqi-gauge-unit">{t('AQI')}:<br /></span>
          <span className="aqi-gauge-value">{(props.aqi < 0) ? "..." : props.aqi || 1}</span>
        </div>
        {/* <canvas
                    ref={chartRef as LegacyRef<HTMLCanvasElement>}
                /> */}
        <Doughnut data={data} options={options} type="doughnut" />
      </div>
    </div>
  )

}
