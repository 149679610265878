import React, { useEffect, useState } from 'react'
// import ChartBuilder from '../chart-builder';
import { Bar } from 'react-chartjs-2';
// import { findLatest } from '../calculations';

// let chart: any = {};

export default function Histogram(props: any) {
    const [data, updateData] = useState({
        labels: ["PC0.1", "PC0.3", "PC0.5", "PC1.0", "PC2.5", "PC5.0", "PC10"],
        datasets: [{
            data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ],
            backgroundColor: ["#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe"],
            hoverBackgroundColor: ["#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe", "#5ccebe"],
            borderWidth: [
                0, 0
            ]
        }]
    });

    const options = {
        scales: {
            y: {
                display: false,
                type: 'logarithmic',
                suggestedMax: 50,
                // beginAtZero: true,
                ticks: {
                    display: false
                }
            },
            x: {
                display: false,
                ticks: {
                    display: false
                }
            }
        },
        animation: false,
        hover: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            // tooltip: {
            //     mode: 'index',
            //     intersect: false,
            //     callbacks: {
            //         label: function (tooltipItem: any, data: any) {
            //             var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            //             return "Histogram" + ": " + tooltipValue.toLocaleString();
            //         }
            //     }
            // },
            legend: {
                display: false
            },
        },
        responsive: true,
        aspectRatio: 1.1,

    };

    useEffect(() => {
        // let i = findLatest(props.chartData, props.device);
        if (props.chartData[0]) {
            //console.log("props.type === Histogram");
            // chart.addHistogramData(props.chartData, props.type, props.device, props.tempScale)

            updateData((data) => {
                return {
                    ...data,
                    datasets: [{
                        ...data.datasets[0],
                        data: [
                            props.chartData[0].pc01,
                            props.chartData[0].pc03,
                            props.chartData[0].pc05,
                            props.chartData[0].pc10,
                            props.chartData[0].pc25,
                            props.chartData[0].pc50,
                            props.chartData[0].pc100,
                        ]
                    }]
                }
            });
        }
    }, [props.chartData]);

    // useEffect(() => {
    //     console.log("device updated");
    // }, [props.device]);
    return (
        <div className="histogram-bar-chart">
            {/* <canvas
                ref={chartRef as LegacyRef<HTMLCanvasElement>}
            /> */}
            <Bar data={data} options={options} type="bar" />
        </div>
    )
}
