import React, { useState } from 'react'
// import ChartBuilder from '../chart-builder';
// import { findLatest, pcToPm } from '../calculations';
import { useTranslation } from "react-i18next";

// let chart: any[] = [];

export default function Gauge(props: any) {
    const { t } = useTranslation();
    // const [init, setInit] = useState(false);
    // const [chartRef] = useState(React.createRef());
    // const [chartIndex, setChartIndex] = useState(0);
    const [gaugeValue] = useState("0.0");
    const [gaugeValue2] = useState("0.0");
    //const [aqiCategory, setAqiCategory] = useState("");

    // useEffect(() => {
    //     //console.log("init Gauge");
    //     // @ts-ignore
    //     const myChartRef = chartRef.current.getContext("2d");
    //     // chart.push(ChartBuilder.buildGauge(myChartRef));
    //     // setChartIndex(chart.length - 1);
    //     setInit(true);
    //     // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     let i = findLatest(props.chartData, props.device);
    //     // if (!init) {
    //     //     console.log("init Gauge");
    //     //     const myChartRef = chartRef.current.getContext("2d");
    //     //     chart.push(ChartBuilder.buildGauge(myChartRef));
    //     //     setChartIndex(chart.length - 1);
    //     //     setInit(true);
    //     // }
    //     //console.log(props.chartData[i]);
    //     if (props.chartData[i]) {
    //         chart[chartIndex].addGaugeData(props.chartData[i], props.type, props.device);
    //         //console.log("addGaugeData");
    //         let pmValues = pcToPm(props.chartData[i]);
    //         if (props.type === "PM1.0") {
    //             setGaugeValue(pmValues.pm10.toFixed(2));
    //         }
    //         if (props.type === "Temperature") {
    //             if (props.tempScale === "F" ) {
    //                 setGaugeValue(((props.chartData[i].temp * 1.8) + 32).toFixed(1) + " °F ");
    //                 setGaugeValue2(props.chartData[i].temp.toFixed(1) + " °C");
    //             } else {
    //                 setGaugeValue(props.chartData[i].temp.toFixed(1) + " °C");
    //                 setGaugeValue2(((props.chartData[i].temp * 1.8) + 32).toFixed(1) + " °F ");
    //             }

    //         }
    //         if (props.type === "Humidity") {
    //             setGaugeValue(props.chartData[i].rh.toFixed(1) + "%");
    //         }
    //         if (props.type === "VOC") {
    //             setGaugeValue(props.chartData[i].voc.toFixed(1) + " KOhms");
    //         }
    //         if (props.type === "Pressure") {
    //             setGaugeValue(props.chartData[i].press.toFixed(1) + " hPa");
    //         }
    //         if (props.type === "CO2") {
    //             setGaugeValue(props.chartData[i].co2.toFixed(0) + " PPM");
    //         }
    //     }
    // }, [init, chartRef, chartIndex, props.chartData, props.type, props.device, props.tempScale]);

    return (
        <div className="gauge">
            <div className="gauge-top">
                <h3>{t(props.type)}</h3>
            </div>
            <div className="gauge-container">
                <div className="gauge-value-wrapper">
                    <div className="gauge-value-align">
                        <span className="gauge-value">{gaugeValue}</span>
                        {props.type === "Temperature" ?
                            <span className="gauge-value-2">{gaugeValue2}</span>
                            : null}
                    </div>
                </div>
                {/* <canvas
                    ref={chartRef as LegacyRef<HTMLCanvasElement>}
                /> */}
            </div>
        </div>
    )

}
