// import React, { useEffect, useState, useContext } from 'react';
// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";
//import { ipcRenderer } from 'electron';
// import Backend from 'i18next-xhr-backend';
import './App.css';
// @ts-ignore
// import { BrowserRouter as Router, Route, NavLink, Switch, useHistory } from "react-router-dom";
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as SensorProvider } from './context/SensorContext';
import { Provider as SettingsProvider } from './context/SettingsContext';
// import Settings from "./screens/Settings"
// import Dashboard from "./screens/Dashboard"
// import DeviceFull from "./screens/DeviceFull"
import Main from './Main'

// const logoImage = require('./logo-2.png');

// i18n
//   .use(Backend)
//   .use(initReactI18next)
//   .init({
//     lng: "en",
//     fallbackLng: "en",

//     interpolation: {
//       escapeValue: false
//     },
//     backend: {
//       loadPath: 'https://sensei.pierasystems.com/locales/{{lng}}/{{ns}}.json',
//     },
//   });

function App(props: any) {
  // const { t } = useTranslation();
  // const history = useHistory();

  // const [minuteAvgs, setMinuteAvgs] = useState<any>([]);
  // const [minuteAvgsCloud, setMinuteAvgsCloud] = useState([]);
  // const [minuteAvgsLocal, setMinuteAvgsLocal] = useState([]);
  // const [events, setEvents] = useState([]);
  // const [devices, setDevices] = useState<any>([]);
  // const [init, setInit] = useState(false);
  // const [deviceConnected, setDeviceConnected] = useState(false);
  // const [deviceName, setDeviceName] = useState("");
  // const [messageBar, setMessageBar] = useState("...");
  // const [deviceOption, setDeviceOption] = useState(0);
  // const [tempScale, setTempScale] = useState('F');
  // const [online, setOnline] = useState(false);
  // const [appPath, setAppPath] = useState("");

  // useEffect(() => {

  //   const timer = setInterval(() => {
  //     tick();
  //   }, 30 * 1000);

  //   if (!init) {
  //     tick();
  //     setInit(true);
  //   }


  //   function tick() {
  //     fetch("https://sensei.pierasystems.com/api/get-devices")
  //       .then(response => response.json())
  //       .then((jsonData) => {
  //         console.log("Updating devices...");
  //         var tempDevices = jsonData.devices;
  //         if (devices.length < jsonData.devices.length) {
  //           console.log("Sorting by lastupdate.");
  //           tempDevices.sort((a: any, b: any) => (new Date(a.lastupdate) < new Date(b.lastupdate)) ? 1 : -1);
  //           tempDevices.forEach((device: any, index: number) => {
  //             tempDevices[index].weight = index;
  //           });
  //         } else {
  //           console.log("Sorting by weight.");

  //           tempDevices.forEach((device: any, index: number) => {
  //             devices.forEach((device2: any, index2: number) => {
  //               if (device.did == device2.did) {
  //                 device.weight = device2.weight;
  //               }
  //             }

  //             );
  //           });
  //           tempDevices.sort((a: any, b: any) => (a.weight > b.weight) ? 1 : -1);
  //         }
  //         setDevices(tempDevices);
  //       })
  //       .catch((error) => {
  //         console.error(error)
  //       });
  //   }

  //   return () => clearInterval(timer);
  // }, [init, devices.length, deviceOption, minuteAvgs, events, deviceConnected]);

  // function deviceChange(event: any) {
  //   console.log("Device changed: " + event.target.value);
  //   history.push("/device/" + event.target.value);
  //   setDeviceOption(event.target.value);
  //   //setMinuteAvgs(minuteAvgs);
  //   //setEvents(events);
  // }

  return (
    <AuthProvider>
      <SensorProvider>
        <SettingsProvider>
          <Main>
            {/*
        <Router>
          <main>
            <nav>
              <div id="logo">
                <img src={logoImage.default} alt="Piera Systems Logo" />
              </div>
              <ul>
                <li><NavLink exact activeClassName="active" to="/">{t('Dashboard')}</NavLink></li>
                <li><NavLink activeClassName="active" to={"/device/" + deviceOption}>{t('Details')}</NavLink></li>
                <li><NavLink activeClassName="active" to="/settings">{t('Settings')}</NavLink></li>
              </ul>

            </nav>
            <div id="route-wrapper">
              <Switch>
                <Route path="/" exact render={(props: any) => <Dashboard {...props} deviceOption={deviceOption} devices={devices} tempScale={tempScale} ></Dashboard>} />
                <Route path="/device/:did" render={(props: any) => <DeviceFull {...props} deviceOption={deviceOption} devices={devices} tempScale={tempScale} setDevice={setDeviceOption}></DeviceFull>} />
                <Route path="/settings" render={(props: any) => <Settings {...props} tempScale={tempScale} setTempScale={setTempScale}></Settings>} />
              </Switch>
            </div>
            <div id="message-bar">
              <div id="message-bar-inner">{messageBar}</div>
            </div>
             <div id="change-device">
          <div className={(online) ? "online" : "offline"}></div>
          <select name="devices" onChange={deviceChange} value={deviceOption}>
            {devices.map(device =>
              <option key={device.did} value={device.did}>{device.name}</option>
            )}
          </select>
        </div> 
          </main>
            </Router> */}
          </Main>
        </SettingsProvider>
      </SensorProvider>
    </AuthProvider>
  );
}

export default App;