import createDataContext from './createDataContext';
import senseiApi from '../api/sensei';
// import { navigate } from '../navigationRef';

const authReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'add_error':
            return { ...state, errorMessage: action.payload };
        case 'signin':
            return { errorMessage: '', token: action.payload };
        case 'clear_error':
            return {...state, errorMessage: ''};
        case 'signout':
            return { errorMessage: '', token: null };
        default:
            return state;
    }
};

const tryLocalSignin = (dispatch: any) => async () =>{
    try {
        const token = "";
        if (token) {
            dispatch({type: 'signin', payload: token});
            // navigate('TrackList');
        } else {
            // navigate('Signup');
        }
    } catch (err) {
        console.log(err);
    }
}

const clearError = (dispatch: any) => () => {
    dispatch({type:'clear_error'});
};

const signup = (dispatch: any) => async ({ email, password }: {email: any, password: any}) => {
    try {
        const response = await senseiApi.post('/signup', { email, password });
        // AsyncStorage.setItem('token', response.data.token, () => {
        //     console.log('token saved');
        // });
        dispatch({ type: 'signin', payload: response.data.token });
        // navigate('TrackList');
    } catch (err) {
        dispatch({ type: 'add_error', payload: 'Something went wrong with sign up.' });
    }
};


const signin = (dispatch: any) => async ({ email, password }: {email: any, password: any}) => {
    try {
        const response = await senseiApi.post('/signin', { email, password });
        // AsyncStorage.setItem('token', response.data.token, () => {
        //     console.log('token saved');
        // });
        dispatch({ type: 'signin', payload: response.data.token });
        // navigate('TrackList');
    } catch (err) {
        console.log(err.message);
        dispatch({ type: 'add_error', payload: 'Something went wrong with sign in.' });
    }
};

const signout = (dispatch: any) => async () => {
    // await AsyncStorage.removeItem('token');
    dispatch({type: 'signout'});
    // navigate('Signup');
};

export const { Provider, Context } = createDataContext(
    authReducer,
    { signup, signin, clearError, tryLocalSignin, signout },
    { token: null, errorMessage: '' }
);