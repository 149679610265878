import createDataContext from './createDataContext';
// import senseiApi from '../api/sensei';
// import { navigate } from '../navigationRef';

const settingsReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'hide_offline':
            return { ...state, hideOffline: action.payload };
        case 'set_temp_scale':
            return { ...state, tempScale: action.payload };
        default:
            return state;
    }
};

const displayOffline = (dispatch: any) => (displayBool: Boolean) => {
    dispatch({type:'hide_offline', payload: displayBool});
};

const setTempScale = (dispatch: any) => (scale: String) => {
    dispatch({type:'set_temp_scale', payload: scale});
};


export const { Provider, Context } = createDataContext(
    settingsReducer,
    { setTempScale, displayOffline },
    { tempScale: "F", hideOffline: false }
);