import React, { useEffect, useState, useRef } from 'react'
// import ChartBuilder from '../chart-builder';
import { Line } from 'react-chartjs-2';
import { getPMs, getPCs, getRatios, getPercentageByCount, getPercentageByMass } from '../calculations';

const pcLabels = [
    { title: "PC0.1", color: "#00FFFF" },
    { title: "PC0.3", color: "#FF0000" },
    { title: "PC0.5", color: "#00FF00" },
    { title: "PC1.0", color: "#7777FF" },
    { title: "PC2.5", color: "#FFFF00" },
    { title: "PC5.0", color: "#FF00FF" },
    { title: "PC10", color: "#FFAAAA" },
];
const pmLabels = [
    { title: "PM0.1", color: "#00FFFF" },
    { title: "PM0.3", color: "#FF0000" },
    { title: "PM0.5", color: "#00FF00" },
    { title: "PM1.0", color: "#7777FF" },
    { title: "PM2.5", color: "#FFFF00" },
    { title: "PM5.0", color: "#FF00FF" },
    { title: "PM10", color: "#FFAAAA" },
];

const ratioLabels = [
    { title: "PC0.1:PC0.3", color: "#00FFFF" },
    { title: "PC0.3:PC0.5", color: "#FF0000" },
    { title: "PC0.5:PC1.0", color: "#00FF00" },
    { title: "PC1.0:PC2.5", color: "#7777FF" },
    { title: "PC2.5:PC5.0", color: "#FFFF00" },
    { title: "PC5.0:PC10.0", color: "#FF00FF" },
];

// let chart: any[] = [];

export default function AnalysisChart(props: any) {
    // const [init, setInit] = useState(false);
    // const [chartRef] = useState(React.createRef());
    const chartRef: any = useRef();
    // const [chartIndex, setChartIndex] = useState(0);
    const [chartType, setChartType] = useState("pc");
    const [pause, setPause] = useState(false);
    const [analysisData, updateData] = useState({
        // labels: ["..."],
        // datasets: [{
        //     backgroundColor: ["#00ffff"],
        //     borderColor:["#00ffff"],
        //     label:"...",
        //     data: [
        //         0
        //     ],
        // }]
    });
    const options = {

        elements: {
            line: {
                cubicInterpolationMode: 'monotone'
            }
        },
        scales: {
            y: {
                // display: false,
                ticks: {
                    color: "#CCC",
                    userCallback: function (value: any, index: any, values: any) {
                        return value.toLocaleString();
                    }
                    //     beginAtZero: true,
                    //     display: false,
                    //     suggestedMax: 50
                }
            },
            x: {
                // display: false,
                ticks: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: "#CCC",
                    // filter: function(item: any, chart: any) {
                    //     // Logic to remove a particular legend item goes here
                    //     return !item.text.includes('PC0.1');
                    // }
                },
                onClick: (event: any, item: any) => {
                    chartRef.current.data.datasets[item.datasetIndex].hidden = !chartRef.current.data.datasets[item.datasetIndex].hidden;
                    chartRef.current.update();
                },

            },
            // tooltip: {
            //   callbacks: {
            //     label: function (tooltipItem, data) {
            //       console.log(tooltipItem);
            //       // console.log(data);
            //       // var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            //       // return tooltipValue.toLocaleString();
            //     }
            //   }
            // },
        },
        responsive: true,
        aspectRatio: 2,

        maintainAspectRatio: false,
        animation: false,
        hover: {
            animationDuration: 0,
        },
        responsiveAnimationDuration: 0,

    };

    useEffect(()=> {
        setPause(false);
    }, [chartType]);

    useEffect(() => {
        // console.log(props.chartData);
        if (props.chartData[0] && !pause) {
            let chartDatasets: any[] = [];
            if (chartType === "pm") {
                let pmValues = getPMs(props.chartData);
                for (let i = 0; i < pmLabels.length; i++) {
                    chartDatasets.push({
                        data: pmValues.values[i],
                        label: pmLabels[i].title,
                        borderColor: [pmLabels[i].color],
                        backgroundColor: [pmLabels[i].color],
                    })
                }

                updateData(oldData => {
                    return {
                        datasets: chartDatasets,
                        // datasets: [{
                        //     ...oldData.datasets[0],
                        //     data: pmValues.pm01,
                        //     label: pmLabels[0].title,
                        // },
                        // {
                        //     ...oldData.datasets[1],
                        //     data: pmValues.pm03,
                        //     label: pmLabels[1].title
                        // }],
                        // ...data,
                        labels: pmValues.labels,
                    }
                });
            }
            if (chartType === "pc") {
                let pcValues = getPCs(props.chartData);
                for (let i = 0; i < pmLabels.length; i++) {
                    chartDatasets.push({
                        data: pcValues.values[i],
                        label: pcLabels[i].title,
                        borderColor: [pcLabels[i].color],
                        backgroundColor: [pcLabels[i].color],
                    })
                }

                updateData(oldData => {
                    return {
                        datasets: chartDatasets,
                        // datasets: [{
                        //     ...oldData.datasets[0],
                        //     data: pcValues.pc01,
                        //     label: pcLabels[0].title,
                        // },
                        // {
                        //     ...oldData.datasets[1],
                        //     data: pcValues.pc03,
                        //     label: pcLabels[1].title
                        // }],
                        // ...data,
                        labels: pcValues.labels,
                    }
                });
            }
            if (chartType === "ratio") {
                let ratioValues = getRatios(props.chartData);
                for (let i = 0; i < ratioLabels.length; i++) {
                    chartDatasets.push({
                        data: ratioValues.values[i],
                        label: ratioLabels[i].title,
                        borderColor: [ratioLabels[i].color],
                        backgroundColor: [ratioLabels[i].color],
                    })
                }

                updateData(oldData => {
                    return {
                        datasets: chartDatasets,
                        // datasets: [{
                        //     ...oldData.datasets[0],
                        //     data: pcValues.pc01,
                        //     label: pcLabels[0].title,
                        // },
                        // {
                        //     ...oldData.datasets[1],
                        //     data: pcValues.pc03,
                        //     label: pcLabels[1].title
                        // }],
                        // ...data,
                        labels: ratioValues.labels,
                    }
                });
            }
            if (chartType === "perc") {
                let percValues = getPercentageByCount(props.chartData);
                for (let i = 0; i < pcLabels.length; i++) {
                    chartDatasets.push({
                        data: percValues.values[i],
                        label: pcLabels[i].title,
                        borderColor: [pcLabels[i].color],
                        backgroundColor: [pcLabels[i].color],
                    })
                }

                updateData(oldData => {
                    return {
                        datasets: chartDatasets,
                        // datasets: [{
                        //     ...oldData.datasets[0],
                        //     data: pcValues.pc01,
                        //     label: pcLabels[0].title,
                        // },
                        // {
                        //     ...oldData.datasets[1],
                        //     data: pcValues.pc03,
                        //     label: pcLabels[1].title
                        // }],
                        // ...data,
                        labels: percValues.labels,
                    }
                });
            }
            if (chartType === "perm") {
                let permValues = getPercentageByMass(props.chartData);
                for (let i = 0; i < pcLabels.length; i++) {
                    chartDatasets.push({
                        data: permValues.values[i],
                        label: pcLabels[i].title,
                        borderColor: [pcLabels[i].color],
                        backgroundColor: [pcLabels[i].color],
                    })
                }

                updateData(oldData => {
                    return {
                        datasets: chartDatasets,
                        // datasets: [{
                        //     ...oldData.datasets[0],
                        //     data: pcValues.pc01,
                        //     label: pcLabels[0].title,
                        // },
                        // {
                        //     ...oldData.datasets[1],
                        //     data: pcValues.pc03,
                        //     label: pcLabels[1].title
                        // }],
                        // ...data,
                        labels: permValues.labels,
                    }
                });
            }
        }
    }, [props.chartData, chartType, pause]);

    // useEffect(() => {
    //     if (!init) {
    //         // @ts-ignore
    //         const myChartRef = chartRef.current.getContext("2d");
    //         chart.push(ChartBuilder.buildLineChart(myChartRef, pcLabels));
    //         setChartIndex(chart.length - 1);
    //         setInit(true);
    //     }
    //     chart[chartIndex].addAnalysisData(props.chartData, props.deviceOption, pcLabels)
    // }, [init, chartRef, chartIndex, props.chartData, props.type, props.deviceOption]);

    return (
        <div className="analysis-wrapper">
            <div className="analysis-chart-wrapper">
                {/* <canvas
                ref={chartRef as LegacyRef<HTMLCanvasElement>}
            /> */}
                <Line ref={chartRef} data={analysisData} options={options} type="line"></Line>
            </div>
            <div id="analysis-options">
                View:
                <select value={chartType} onChange={(event) => setChartType(event.target.value)}>
                    <option value="pc">PC Values</option>
                    <option value="pm">PM Values</option>
                    <option value="ratio">Ratios</option>
                    <option value="perc">% by Count</option>
                    <option value="perm">% by Mass</option>
                </select>
                {(!pause) ?
                    <button className="pause" onClick={() => { setPause(true) }}>Pause</button>
                    :
                    <button className="resume" onClick={() => { setPause(false) }}>Resume</button>
                }
            </div>
        </div>
    )
}