import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import AqiGauge from "./AqiGauge"
import Gauge from "./Gauge"
import { getTimeInterval, getAqiLevel } from "../calculations"
// @ts-ignore
import { NavLink } from "react-router-dom";
import { Context as SettingsContext } from '../context/SettingsContext';

export default function Device(props: any) {
  const [tempChartData, setChartData] = useState<any>([]);
  const [tempEventData, setEventData] = useState([]);
  const [tempDevice, setTempDevice] = useState(0);
  const [airQuality, setAirQuality] = useState("");
  // const [latestID, setLatestID] = useState(-1);
  const { state } = useContext(SettingsContext);
  const [online, setOnline] = useState(true);
  const [lastOnline, setLastOnline] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    setTempDevice(props.deviceOption);
    if (props.chartData.length > 0) {
      // setLatestID(findLatest(props.chartData, tempDevice));
      setChartData(props.chartData);
      // let i = findLatest(props.chartData, props.deviceOption);
      let mostRecent = new Date(props.lastUpdate);
      setLastOnline(mostRecent.getTime());
      mostRecent.setSeconds(mostRecent.getSeconds() + 300);
      let now = new Date();
      if (mostRecent > now) {
        setOnline(true);
      } else {
        setOnline(false);
      }
      if (props.device.pm100aqi > props.device.pm25aqi) {
        setAirQuality(getAqiLevel(props.device.pm100aqi));
      } else {
        setAirQuality(getAqiLevel(props.device.pm25aqi));
      }
    }


    if (props.hasOwnProperty("events") && props.events !== undefined) {
      let deviceEvents = props.events.filter((e: any) => {
        return parseInt(e.did) === tempDevice;
      });
      setEventData(deviceEvents);
    }





  }, [props, tempDevice, tempChartData]);//, tempEventData, tempDevice, latestID]);

  return (
    <Fragment>
      {((state.hideOffline && online) || !state.hideOffline) ?
        <div className="device">
          <div className="device-name">
            <NavLink activeClassName="active" to={"/device/" + props.device.did}>
              <h3>{props.device.name || props.device.iotid}</h3>
            </NavLink>
            {(!props.index) ?
              <div className="tooltip-top tooltip-mobile" data-tooltip={t("status-tooltip")}>
                ?
              </div>
              : null}
            <NavLink activeClassName="active" to={"/device/" + props.device.did}>
              <div className="device-status">
                <div className={(online) ? "online" : "offline"}></div>
                {(online) ?
                  <span>{t('online')}</span>
                  :
                  <span>{t('last-online') + " " + getTimeInterval(lastOnline)}</span>
                }
              </div>
            </NavLink>
          </div>
          {(props.device.pm100aqi > -1) ?
            <div className="device-widget">
              <AqiGauge aqi={props.device.pm100aqi} pmvalue={0} type={"PM10"}></AqiGauge>
            </div>
            : null}
          {(props.device.pm25aqi > -1) ?
            <div className="device-widget">
              <AqiGauge aqi={props.device.pm25aqi} pmvalue={0} type={"PM2.5"}></AqiGauge>
            </div>
            : null}
          {(props.device.pm10aqi > -1) ?
            <div className="device-widget">
              <AqiGauge aqi={props.device.pm10aqi} pmvalue={0} type={"PM1.0"}></AqiGauge>
              {(!props.index) ?
                <div className="tooltip-top tooltip-mobile tooltip-widget" data-tooltip={t("pm-tooltip")}>
                  ?
                </div>
                : null}
            </div>
            : null}
          {(props.device.hasOwnProperty("temp") && props.device.temp > -274) ?
            <div className="device-widget">
              <Gauge chartData={tempChartData} device={tempDevice} type={"Temperature"} tempScale={props.tempScale}></Gauge>
            </div>
            : null}
          {(props.device.hasOwnProperty("rh") && props.device.rh > -1) ?
            <div className="device-widget">
              <Gauge chartData={tempChartData} device={tempDevice} type={"Humidity"}></Gauge>
            </div>
            : null}
          <div className="device-widget event-widget">
            <div>
              {t("air-quality")}: <span className={airQuality + "-text air-quality"}>{t(airQuality)}</span>
            </div>
            <NavLink activeClassName="active" to={"/device/" + props.device.did}>
              <div className={"recent-alerts"}>
                <span className={(tempEventData.length) ? "highlight" : ""}>{tempEventData.length + " " + ((tempEventData.length !== 1) ? t('recent-alerts') : t('recent-alert'))}</span>
              </div>
            </NavLink>
            {(!props.index) ?
              <div className="tooltip-top tooltip-mobile tooltip-widget" data-tooltip={t("aqi-tooltip")}>
                ?
              </div>
              : null}
          </div>
        </div>
        : null}
    </Fragment>
  )
}