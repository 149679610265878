import React, { useState, useEffect, useContext } from 'react'
//@ts-ignore
import { useHistory, useParams } from "react-router-dom";
import { Context as SensorContext } from "../context/SensorContext";
import { Context as SettingsContext } from "../context/SettingsContext";
// import { findLatest } from "../calculations"


export default function SelectDevice(props: any) {
    const history = useHistory();
    let { did } = useParams();
    //let location = useLocation();
    //let { did } = useParams();
    const { state } = useContext(SensorContext);
    const settingsCtx = useContext(SettingsContext);
    let settings = settingsCtx.state;
    const [deviceOptions, setDeviceOptions] = useState([{ name: "Select device...", did: -1 }]);
    // const [online, setOnline] = useState(false);
    // const [deviceOption, setDeviceOption] = useState(did);
    // const [minuteAvgs, setMinuteAvgs] = useState([]);

    function deviceChange(event: any) {
        if (event.target.value !== "-1") {
            if (parseInt(event.target.value) !== parseInt(did)) {
                history.push("/device/" + event.target.value);
                // setDeviceOption(event.target.value);
            }
        }
    }

    useEffect(() => {
        setDeviceOptions([{ name: "Select device...", did: -1 }, ...state.devices]);
    }, [state]);

    // useEffect(() => {
    // console.log(props.devices);
    // console.log(props.device);
    // setDeviceOption(props.device);
    //console.log("location change");
    //console.log(location);
    // if (history.location.pathname.substring(0, 8) === "/device/" ) {
    //     setDisplay(true);
    // } else {
    //     setDisplay(false);
    // }
    // }, [props.device]);

    // useEffect(() => {
    //     console.log("props.minuteAvgs change");
    //     if (minuteAvgs.length > 0) {
    //         let i = findLatest(props.minuteAvgs, deviceOption);
    //         let mostRecent = new Date(props.minuteAvgs[i].time);
    //         mostRecent.setSeconds(mostRecent.getSeconds() + 300);
    //         let now = new Date();
    //         if (mostRecent > now) {
    //             setOnline(true);
    //         } else {
    //             setOnline(false);
    //         }
    //     }
    // }, [props.minuteAvgs]);

    return (
        <div id="change-device">
            <div>
                {/* <div className={(online) ? "online" : "offline"}></div> */}
                View device:
                <select name="devices" onChange={deviceChange} value={did}>
                    {deviceOptions.filter((device: any ) => {
                        return device.online || !settings.hideOffline
                    })
                    .map((device: any) =>
                        <option key={device.did} value={device.did}>{(device.name == null) ? device.iotid : device.name}</option>
                    )}
                </select>
            </div>
        </div>
    )

}
