import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { pm100Aqi, pm25Aqi, getAqiColor } from '../calculations';

// let chart: any[] = [];

export default function AqiBarChart(props: any) {
    // const [chartRef] = useState<React.RefObject<HTMLCanvasElement>>(React.createRef());
    const [data, updateData] = useState({
        labels: [""],
        datasets: [{
            data: [
                ""
            ],
            backgroundColor: ["#00e000"],
            hoverBackgroundColor: ["#00e000"],
            borderWidth: [
                0, 0
            ]
        }]
    });
    const options = {
        scales: {
            y: {
                display: false,
                type: 'logarithmic',
                max: 2000,
                // beginAtZero: true,
                ticks: {
                    display: false
                }
            },
            x: {
                display: false,
                ticks: {
                    display: false
                }
            }
        },
        animation: false,
        hover: {
            mode: 'index',
            intersect: false
        },
        plugins: {
            // tooltip: {
            //     mode: 'index',
            //     intersect: false,
            //     callbacks: {
            //         label: function (tooltipItem: any, data: any) {
            //             var tooltipValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            //             return "Histogram" + ": " + tooltipValue.toLocaleString();
            //         }
            //     }
            // },
            legend: {
                display: false
            },
        },
        responsive: true,
        aspectRatio: 2.2,

    };

    // useEffect(() => {
    //      if (!init) {
    //          // @ts-ignore
    //         const myChartRef = chartRef.current.getContext("2d");
    //         chart[props.type] = ChartBuilder.buildBarChart(myChartRef, props.type);
    //         setInit(true);
    //     }
    // chart[props.type].addAQIData(props.chartData,props.type, props.device)
    // }, [init, chartRef, props.chartData, props.type, props.device]);

    useEffect(() => {
        // let i = findLatest(props.chartData, props.device);
        // console.log(props.chartData);
        if (props.chartData[0] && props.chartData[0].pm25) {
            //console.log("props.type === Histogram");
            // chart.addHistogramData(props.chartData, props.type, props.device, props.tempScale)

            // this.data.labels = [];
            // this.data.datasets[0].data = [];
            // this.data.datasets[0].backgroundColor = [];
            // this.data.datasets[0].borderColor = [];
            let aqiLabels: string[] = [];
            let aqiData: string[] = [];
            let aqiColors: string[] = [];
            for (let i = 0; i < props.chartData.length; i++) {
                let aqi = { value: 0, category: "good" };
                let pmValue = 0;
                if (props.type === "PM1.0") {
                    pmValue = props.chartData[i].pm10
                    aqi = pm25Aqi(pmValue);
                }
                if (props.type === "PM2.5") {
                    pmValue = props.chartData[i].pm25;
                    aqi = pm25Aqi(pmValue);
                }
                if (props.type === "PM10") {
                    pmValue = props.chartData[i].pm100;
                    aqi = pm100Aqi(pmValue);
                }
                // if (props.type === "VOC") {
                //     value = props.chartData.voc;
                //     aqi = vocAqi(data[i].voc);
                // }
                let time = new Date(props.chartData[i].time);
                aqiLabels.unshift(
                    "AQI: " + aqi.value +
                    `
TIME: ` + time.toLocaleString());
                if (pmValue === 0) {
                    pmValue = 0.00
                }
                aqiData.unshift(pmValue.toFixed(2));
                aqiColors.unshift(getAqiColor(aqi.category));
            }

            updateData(data => {
                return {
                    ...data,
                    labels: aqiLabels,
                    datasets: [{
                        ...data.datasets[0],
                        data: aqiData,
                        backgroundColor: aqiColors,
                        hoverBackgroundColor: aqiColors
                    }]
                    // labels: [""],
                    // datasets: [{
                    //     data: [
                    //         1
                    //     ],
                    //     backgroundColor: ["#00e000"],
                    //     hoverBackgroundColor: ["#00e000"],
                    //     borderWidth: [
                    //         0, 0
                    //     ]
                    // }]
                }
            });
        }
    }, [props.chartData, props.type]);

    return (
        <div className="aqi-bar-chart">
            {/* <canvas
                ref={chartRef as LegacyRef<HTMLCanvasElement>}
            /> */}
            <Bar data={data} options={options} type="bar" />
        </div>
    )
}
