import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import AqiBarChart from "../components/AqiBarChart"
import AqiGauge from "../components/AqiGauge"
// import BarChart from "../components/BarChart"
import Histogram from "../components/Histogram"
// import Gauge from "../components/Gauge"
import SelectDevice from "../components/SelectDevice"
import AnalysisChart from "../components/AnalysisChart"
import { getTimeInterval, pcToPm, pm100Aqi, pm25Aqi } from "../calculations"
// @ts-ignore
import { useParams } from "react-router-dom";
import senseiApi from "../api/sensei";
import { Context as SensorContext } from "../context/SensorContext";
import { version } from '../../package.json';


export default function DeviceFull(props: any) {
  const [tempChartData, setChartData] = useState<any>([]);
  const [tempEventData, setEventData] = useState([]);
  const [tempDevice, setTempDevice] = useState(0);
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [online, setOnline] = useState(true);
  // const [latestID, setLatestID] = useState(-1);
  const [pms, setPms] = useState([{ pm01: null, pm03: null, pm05: null, pm10: null, pm25: null, pm50: null, pm100: null }]);
  const [aqi10, setAqi10] = useState(-1);
  const [aqi25, setAqi25] = useState(-1);
  const [aqi100, setAqi100] = useState(-1);
  const [timeSpan, setTimeSpan] = useState("hour");
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const { state } = useContext(SensorContext);
  let { did } = useParams();
  let { setDevice } = props;

  useEffect(() => {
    if (tempDevice !== did) {
      // Device changed
      setPage(0);
      // setFirstDate("");
      // setLastDate("");
      // setTimeSpan("hour");
    }
    setTempDevice(did);
    setDevice(did);

    let isMounted = true;

    setPms([{ pm01: null, pm03: null, pm05: null, pm10: null, pm25: null, pm50: null, pm100: null }]);
    setAqi10(-1);
    setAqi25(-1);
    setAqi100(-1);
    setChartData([]);
    // setEventData([]);
    const tick = async () => {
      if (isMounted) {

        let response;
        if (timeSpan === "hour") {
          response = await senseiApi.get(`get-minute-averages/${did}?page=${page}`);
        } else {
          response = await senseiApi.get(`get-hour-averages/${did}?page=${page}`);
        }
        // await fetch("https://sensei.pierasystems.com/api/get-minute-averages/" + did)
        // .then(response => response.json()
        // .then((jsonData) => {
        // console.log("Updating minute averages...");
        // console.log(response);

        if (response.data.data[0]) {
          setFirstDate(new Date(response.data.data[0].time).toLocaleString());
          setLastDate(new Date(response.data.data[response.data.data.length - 1].time).toLocaleString());
          setChartData(response.data.data);
          let pmTemp = response.data.data.map((ele: any) => {
            const pmVals: any = pcToPm(ele);
            pmVals.time = ele.time;
            return pmVals;
          });
          if (pmTemp.length > 0) {
            setPms(pmTemp);
            setAqi10(pm25Aqi(pmTemp[0].pm10).value);
            setAqi25(pm25Aqi(pmTemp[0].pm25).value);
            setAqi100(pm100Aqi(pmTemp[0].pm100).value);
          }
        }
      }
      // }
      // ));
      const eventResponse = await senseiApi.get("get-events/" + did);
      if (isMounted) {
        setEventData(eventResponse.data.data);
      }
      // await fetch("https://sensei.pierasystems.com/api/get-events/" + did)
      //   .then(response => response.json()
      //     .then((jsonData) => {
      //       console.log("Updating events...");
      //       if (isMounted) { 
      //         setEventData(jsonData.data);
      //       }
      //     }
      //     ));
    }
    let tickInt = setInterval(tick, 30 * 1000);

    tick();
    // if (did != props.deviceOption && init) {
    //   props.history.push("/device/" + props.deviceOption);
    //   setTempDevice(props.deviceOption);
    // }

    // if (props.chartData.length > 0) {
    //   setLatestID(findLatest(props.chartData, tempDevice));
    //   setChartData(props.chartData);
    // }

    // if (props.events.length > 0) {
    //   let deviceEvents = props.events.filter((e: any) => {
    //     return parseInt(e.did) === parseInt(did);
    //   });
    //   setEventData(deviceEvents);
    // }

    return (() => {
      isMounted = false;
      clearInterval(tickInt);
    });

  }, [did, setDevice, timeSpan, page]);//, tempEventData, tempDevice, latestID]);

  useEffect(() => {
    let info = state.devices.filter((device: any) => device.did === parseInt(did));
    if (info.length) {
      if (info[0].name) {
        setName(info[0].name);
      } else {
        setName(info[0].iotid)
      }
      setSerial(info[0].iotid);
      setLastUpdate(new Date(info[0].lastupdate));
      let lastUpdateTime = new Date(info[0].lastupdate);
      lastUpdateTime.setSeconds(new Date(info[0].lastupdate).getSeconds() + 300);
      let now = new Date();
      if (lastUpdateTime > now) {
        setOnline(true);
      } else {
        setOnline(false);
      }
    }
  }, [state.devices, did]);

  return (
    <Fragment>
      <div id="device-info">
        <h1>{name}<div className={(online) ? "online" : "offline"}></div></h1>
        <div>{t("Serial #")}: {serial}</div>
        <div>{t("Last update")}: {getTimeInterval(lastUpdate)}</div>
        <div className="tooltip-left tooltip-mobile" data-tooltip={t("name-tooltip")}>?</div>
      </div>
      <div id="device-full">
        <div id="pm-100" className="dash-widget">
          <AqiGauge aqi={aqi100} pmvalue={(pms[0].pm100) ? pms[0].pm100 : 0} type={"PM10"}></AqiGauge>
          <AqiBarChart chartData={pms
            // pms.map((ele: any) => {
            //   return ele.pm100
            // })
          } device={tempDevice} type={"PM10"}></AqiBarChart>
          <div className="tooltip-top tooltip-mobile" data-tooltip={t("pm100-tooltip")}>?</div>
        </div>
        <div id="pm-25" className="dash-widget">
          <AqiGauge aqi={aqi25} pmvalue={(pms[0].pm25) ? pms[0].pm25 : 0} type={"PM2.5"}></AqiGauge>
          <AqiBarChart chartData={pms} type={"PM2.5"}></AqiBarChart>
          <div className="tooltip-top tooltip-mobile" data-tooltip={t("pm25-tooltip")}>?</div>
        </div>
        <div id="pm-10" className="dash-widget">
          <AqiGauge aqi={aqi10} pmvalue={(pms[0].pm10) ? pms[0].pm10 : 0} type={"PM1.0"}></AqiGauge>
          <AqiBarChart chartData={pms} type={"PM1.0"}></AqiBarChart>
          <div className="tooltip-top tooltip-mobile" data-tooltip={t("pm10-tooltip")}>?</div>
        </div>
        <div id="histogram" className="dash-widget">
          <h3>{t('Histogram')}</h3>
          <Histogram chartData={tempChartData}></Histogram>
          <ul id="histogram-labels">
            <li>{t("PC0.1")}</li>
            <li>{t("PC0.3")}</li>
            <li>{t("PC0.5")}</li>
            <li>{t("PC1.0")}</li>
            <li>{t("PC2.5")}</li>
            <li>{t("PC5.0")}</li>
            <li>{t("PC10")}</li>
          </ul>
          <div className="tooltip-left tooltip-mobile" data-tooltip={t("histogram-tooltip")}>?</div>
        </div>
        <div className="time-range"><span>{lastDate}</span> to <span>{firstDate}</span></div>
        <div className="select-time">{t("Time span")}:
          <span className={`time-span  ${(timeSpan === "hour") ? "active" : ""}`}
            onClick={() => {
              setTimeSpan("hour");
              if (timeSpan !== "hour")
                setPage(0);
            }}>{t("1H")}</span>
          <span className={`time-span  ${(timeSpan === "day") ? "active" : ""}`}
            onClick={() => {
              setTimeSpan("day")
              if (timeSpan !== "day")
                setPage(0);
            }}>{t("24H")}</span>
          <div className="pagers">
            <button className="pager" disabled={(tempChartData.length < 24)} onClick={() => {
              setPage(page + 1)
            }
            }>{"<"}</button>
            <button className="pager" disabled={(page === 0)} onClick={() => {
              if (page > 0)
                setPage(page - 1)
            }}>{">"}</button>
          </div>
        </div>
        {/* {(latestID > -1 && tempChartData[latestID].temp > -274) ?
          <div id="temp" className="dash-widget">
            <Gauge chartData={tempChartData} device={tempDevice} type={"Temperature"} tempScale={props.tempScale}></Gauge>
            <BarChart chartData={tempChartData} device={tempDevice} type={"Temperature"} tempScale={props.tempScale}></BarChart>
          </div>
          : null}
        {(latestID > -1 && tempChartData[latestID].rh > -1) ?
          <div id="rh" className="dash-widget">
            <Gauge chartData={tempChartData} device={tempDevice} type={"Humidity"}></Gauge>
            <BarChart chartData={tempChartData} device={tempDevice} type={"Humidity"}></BarChart>
          </div>
          : null}
        {(latestID > -1 && tempChartData[latestID].voc > -1) ?
          <div id="voc" className="dash-widget">
            <AqiGauge chartData={tempChartData} device={tempDevice} type={"VOC"}></AqiGauge>
            <AqiBarChart chartData={tempChartData} device={tempDevice} type={"VOC"}></AqiBarChart>
          </div>
          : null}
        {(latestID > -1 && tempChartData[latestID].press > -1) ?
          <div id="press" className="dash-widget">
            <Gauge chartData={tempChartData} device={tempDevice} type={"Pressure"}></Gauge>
            <BarChart chartData={tempChartData} device={tempDevice} type={"Pressure"}></BarChart>
          </div>
          : null}
        {(latestID > -1 && tempChartData[latestID].co2 > -1) ?
          <div id="co2" className="dash-widget">
            <Gauge chartData={tempChartData} device={tempDevice} type={"CO2"}></Gauge>
            <BarChart chartData={tempChartData} device={tempDevice} type={"CO2"}></BarChart>
          </div>
          : null} */}
        {(tempEventData.length > 0) ?
          <div id="events" className="dash-widget">
            <h3>{t('Recent Alerts')}</h3>
            <ul>{tempEventData.map((e: any, i) =>
              <li key={i}><strong>{t(e.type + "-detected")}</strong><br />
                - {getTimeInterval(new Date(e.time))}</li>
            )}</ul>
            <div className="tooltip-left tooltip-mobile" data-tooltip={t("alerts-tooltip")}>?</div>
          </div>
          : null}
        {(tempEventData.length === 0) ?
          <div id="events" className="dash-widget">
            <h3>{t('Recent Alerts')}</h3>
            <ul>
              <li><span>{t('no-events')}</span></li>
            </ul>
            <div className="tooltip-left tooltip-mobile" data-tooltip={t("alerts-tooltip")}>?</div>
          </div>
          : null}
        <div className="tooltip-analysis">
          <div className="tooltip-left tooltip-mobile" data-tooltip={t("analysis-tooltip")}>?</div>
        </div>
        <AnalysisChart chartData={tempChartData} deviceOption={tempDevice} type={"PM2.5"}></AnalysisChart>
        <div className="time-range"><span>{lastDate}</span> to <span>{firstDate}</span></div>
        <div className="select-time">{t("Time span")}:
          <span className={`time-span  ${(timeSpan === "hour") ? "active" : ""}`}
            onClick={() => {
              setTimeSpan("hour");
              if (timeSpan !== "hour")
                setPage(0);
            }}>{t("1H")}</span>
          <span className={`time-span  ${(timeSpan === "day") ? "active" : ""}`}
            onClick={() => {
              setTimeSpan("day")
              if (timeSpan !== "day")
                setPage(0);
            }}>{t("24H")}</span>
          <div className="pagers">
            <button className="pager" disabled={(tempChartData.length < 24)} onClick={() => {
              setPage(page + 1)
            }
            }>{"<"}</button>
            <button className="pager" disabled={(page === 0)} onClick={() => {
              if (page > 0)
                setPage(page - 1)
            }}>{">"}</button>
          </div>
        </div>
      </div>

      {/* <SelectDevice devices={state.devices}></SelectDevice> */}
      <div id="message-bar">
        <div id="message-bar-inner">{"v" + version}</div>
        <div id="message-bar-right">
          <SelectDevice></SelectDevice>
        </div>
      </div>
    </Fragment>
  )
}