import React, { Fragment, useContext } from 'react';
import { Context as SensorContext } from '../context/SensorContext';

import Device from '../components/Device';
import SelectDevice from '../components/SelectDevice';
import { version } from '../../package.json';

function Dashboard(props: any) {
  const { state } = useContext(SensorContext);

  return (
    <Fragment>
      <div id="dashboard">
        {state.devices.map((device: any, index: number) =>
          // <div  className="device">
            <Device key={device.did} device={device} deviceOption={device.did} index={index} lastUpdate={device.lastupdate} deviceName={(device.name) ? device.name : device.iotid} chartData={[device.pm100aqi, device.pm25aqi]} tempScale={props.tempScale} events={device.events}></Device>
          // </div>
        )}
        <div id="message-bar">
          <div id="message-bar-inner">{"v" + version}</div>
          <div id="message-bar-right">
            <SelectDevice devices={state.devices}></SelectDevice>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Dashboard;